import { createSlice } from '@reduxjs/toolkit';

export enum ConnectionType {
  Disconnected,
  InProgress,
  Connected,
}

interface InitialState {
  connectionStatus?: ConnectionType;
  useSlotsFromDB: boolean;
}

const initialState: InitialState = {
  useSlotsFromDB: localStorage.getItem('useSlotsFromDB') === 'true',
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    connect: (state) => {
      state.connectionStatus = ConnectionType.InProgress;
    },
    disconnect: (state) => {
      state.connectionStatus = ConnectionType.Disconnected;
    },
    connected: (state) => {
      state.connectionStatus = ConnectionType.Connected;
    },
    setUseSlotsFromDB: (state, action) => {
      localStorage.setItem('useSlotsFromDB', String(action.payload));
      state.useSlotsFromDB = action.payload;
    },
  },
});

export const { connect, connected, disconnect, setUseSlotsFromDB } = slice.actions;
export default slice.reducer;
