import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

import styles from './ReportChart.module.scss';
import { useIntl } from 'react-intl';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
);

type Props = {
  type: 'monthly' | 'weekly';
  monthlyTarget: number
  reportData: any
};

export default function ReportChart({ type, monthlyTarget, reportData }: Props) {
  const intl = useIntl();
  
  if (!reportData || reportData.length === 0) {
    return <>No data yet</>
  }

  const data = type === 'monthly' ? reportData.monthly : reportData.weekly;

  const targetValue = type === 'monthly' ? monthlyTarget : Math.round((12 * monthlyTarget) / 52);

  const chartTitle = type === 'monthly' ? 'Monthly Report' : 'Weekly Report';
  const options = {
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: chartTitle,
      },
      annotation: {
        annotations: {
          horizontalLine: {
            type: 'line' as const,
            mode: 'horizontal',
            scaleID: 'y',
            value: targetValue,
            borderColor: 'rgba(255,105,97, 0.9)',
            borderWidth: 3,
          },
          label1: {
            type: 'label' as const,
            xValue: type === 'monthly' ? 0 : 1.8,
            yValue: targetValue + (type === 'monthly' ? 5 : 1.5),
            color: 'rgb(75, 75, 75)',
            content: [`${intl.formatMessage({ id: 'common.targetValue' })}: ${targetValue}`],
            font: {
              size: 14,
            }
          }
        }
      }
    },
  };

  return (    
      <div className={styles.reportModuleWrapper}>
        <Chart type="bar" data={data} options={options}  />
      </div>
      
  );
}
