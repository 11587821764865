import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { OverviewHeader } from './subcomponents';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import styles from './ReportsContainer.module.scss';
import ReportChart from './components/ReportChart';
import { IOSSwitch, LoadingWrapper } from '@linetweet/linetweet-ui';
import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { getReportsData } from 'store/reports/thunks';
import { clearReportData } from 'store/reports/slice';
import { shallowEqual } from 'react-redux';

type Props = {
  date: dayjs.Dayjs;
  onClose: () => void;
};

export function ReportsContainer({ date, onClose }: Props) {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const containerElement = useRef(null);

  const storeState = useAppSelector((state) => state.store);

  const monthlyTarget = storeState.data.settings?.monthlyTarget ?? 100;

  const reportsDataState = useAppSelector((state) => state.reports, shallowEqual);

  const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);
  const [reportChartType, setReportChartType] = useState<'monthly' | 'weekly'>('monthly');

  const fetchPage = async () => {
    dispatch(
      getReportsData({
        storeId: storeState.data.id,
        enterpriseId: storeState.data.enterpriseId,
      }),
    );
  };

  useEffect(() => {
    dispatch(clearReportData());
    fetchPage();
    setInitialFetchCompleted(true);
  }, []);

  return (
      <div className={styles.calendar_overview_wrapper} ref={containerElement}>
        <OverviewHeader date={date} onBackClick={onClose} />
        <LoadingWrapper loading={!reportsDataState.queryHaveResponse}>
          <Stack direction="row" marginLeft="auto" alignItems="center">
            <Box marginLeft={3} marginRight={2}>
              <Typography fontWeight={500} fontSize={16}>
                {intl.formatMessage({ id: 'common.weeklyReport' })}
              </Typography>
            </Box>
            <Box>
              <IOSSwitch checked={reportChartType === 'monthly'} onChange={() => setReportChartType(reportChartType === 'monthly' ? 'weekly' : 'monthly')} />
            </Box>
            <Box marginLeft={2}>
              <Typography fontWeight={500} fontSize={16}>
              {intl.formatMessage({ id: 'common.monthlyReport' })}
              </Typography>
            </Box>
          </Stack>
          {reportsDataState.queryHaveResponse && <ReportChart type={reportChartType} monthlyTarget={monthlyTarget} reportData={reportsDataState?.data} />}
        </LoadingWrapper>
      </div>
  );
}
