import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { shallowEqual } from 'react-redux';

import { EventsOverview } from './components';
import { OverviewHeader } from './subcomponents';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { DayOverviewType, DaySummary } from '../../types';
import { isArrayEqual, TimeHelper } from '../../utils';
import { getEventStats } from '../../store/eventStats/thunks';
import { convertDaySummaryToCalendarDataType, getCalendarGridStartEndDates } from '../commons/services/calendarOverviewService';
import { selectStoreServices } from '../../store/store/selectors';
import { useIntl } from 'react-intl';

import styles from './CalendarOverview.module.scss';
import { clearEventStats } from 'store/eventStats/slice';

const ADDED_MONTHS = 2;

type Props = {
  date: dayjs.Dayjs;
  onClose: () => void;
};

export function CalendarOverview({ date, onClose }: Props) {
  const dispatch = useAppDispatch();
  const containerElement = useRef(null);
  const intl = useIntl();

  const { useSlotsFromDB } = useAppSelector((state) => state.global);
  const storeState = useAppSelector((state) => state.store);
  const eventStatsState = useAppSelector((state) => state.eventStats, shallowEqual);
  const allServices = useAppSelector(selectStoreServices, shallowEqual);

  const currentDate = TimeHelper.toDayjs(Date.now()).startOf('day');
  const [prevPageDate, setPrevPageDate] = useState(date.clone().subtract(1, 'month'));
  const [nextPageDate, setNextPageDate] = useState(date.clone().add(1, 'month'));
  const [scrollDate, setScrollDate] = useState(date);
  const [isLoading, setIsLoading] = useState({ prev: false, next: false });
  const [renderData, setRenderData] = useState<Record<string, DayOverviewType>>({});
  const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);
  const [visibleMonth, setVisibleMonth] = useState<string>('');

  const fetchPage = async (fetchDate: Dayjs, addMonths: number, noCheck = false) => {
    const rangeDates = getCalendarGridStartEndDates(fetchDate, addMonths);
    const { startOfPeriod, endOfPeriod } = rangeDates;

    if (!noCheck && eventStatsState.queryHaveResponse[`${startOfPeriod.valueOf()}-${endOfPeriod.valueOf()}`]) {
      return rangeDates;
    }

    dispatch(
      getEventStats({
        from: startOfPeriod,
        to: endOfPeriod,
        storeId: storeState.data.id,
        enterpriseId: storeState.data.enterpriseId,
      }),
    );
    return rangeDates;
  };

  useEffect(() => {
    dispatch(clearEventStats());
    fetchPage(prevPageDate, ADDED_MONTHS, true);
    setInitialFetchCompleted(true);
  }, []);

  useEffect(() => {
    if (initialFetchCompleted) {
      dispatch(clearEventStats());
      fetchPage(prevPageDate, ADDED_MONTHS, true);
    }
  }, [useSlotsFromDB]);

  const handleScroll = useCallback(
    async (isNext: boolean) => {
      if (eventStatsState.loading) {
        return;
      }
      let fetchDate;
      let updatedScrollDate;

      if (isNext) {
        if (isLoading.next) {
          return;
        }
        fetchDate = nextPageDate.add(1, 'month');
        const rangeDates = await fetchPage(fetchDate, 0);
        updatedScrollDate = rangeDates.startOfPeriod;
        setIsLoading({ ...isLoading, next: true });
        setNextPageDate(fetchDate);
      } else {
        if (isLoading.prev) {
          return;
        }
        fetchDate = prevPageDate.subtract(1, 'month');
        const rangeDates = await fetchPage(fetchDate, 0);
        updatedScrollDate = rangeDates.endOfPeriod;
        setIsLoading({ ...isLoading, prev: true });
        setPrevPageDate(fetchDate);
      }

      setScrollDate(updatedScrollDate);
    },
    [nextPageDate, prevPageDate, eventStatsState, isLoading],
  );

  useEffect(() => {
    const updatedRenderData = {};
    const existingData = Object.entries(eventStatsState.data).reduce((acc, [dayStr, dayStats]) => {
      acc[dayStr] = dayStats;
      return acc;
    }, {} as Record<string, DaySummary>);

    Object.entries(existingData).forEach(([dayStr, dayData]) => {
      updatedRenderData[dayStr] = convertDaySummaryToCalendarDataType(dayData, allServices);
    });

    if (!isArrayEqual(renderData, updatedRenderData)) {
      setRenderData({ ...renderData, ...updatedRenderData });
      setTimeout(() => {
        setIsLoading({ prev: false, next: false });
      }, 500);
    }
  }, [eventStatsState.data]);

  const isDateMonthLoaded = useMemo(() => {
    const rangeDates = getCalendarGridStartEndDates(date.clone().subtract(1, 'month'), ADDED_MONTHS);
    const { startOfPeriod, endOfPeriod } = rangeDates;

    if (!eventStatsState.queryHaveResponse[`${startOfPeriod.valueOf()}-${endOfPeriod.valueOf()}`]) {
      return false;
    }

    return initialFetchCompleted;
  }, [date, eventStatsState.queryHaveResponse, eventStatsState.data, initialFetchCompleted]);

  const monthStats = useMemo(() => {
    if (!Object.keys(eventStatsState.data).length || !visibleMonth) return [];
    const visibleMonthDayjs = TimeHelper.toDayjs(visibleMonth, 'Europe/Berlin', 'MMMM YYYY');
    const visibleMonthDayjsMonth = visibleMonthDayjs.format('MMMM');
    const visibleMonthDayjsYear = visibleMonthDayjs.format('YYYY');
    const neededObjectKeys = Object.keys(eventStatsState.data)
      .filter((key) => {
        const day = TimeHelper.toDayjs(key);
        return day.format('YYYY') === visibleMonthDayjsYear && day.format('MMMM') === visibleMonthDayjsMonth;
      });

    const servicesMapping = {};
    neededObjectKeys.forEach((key) => {
      const servicesData = eventStatsState.data[key].services;

      servicesData.forEach((serviceData) => {
        const serviceId = serviceData.services[0];

        if (servicesMapping[serviceId] === undefined) {
          servicesMapping[serviceId] = {
            bookedAppointments: 0,
            total: 0,
          };
        }
        servicesMapping[serviceId].bookedAppointments += serviceData.appointments;
        servicesMapping[serviceId].total += serviceData.slots + serviceData.appointments;
      });
    });

    return Object.keys(servicesMapping).map((serviceId) => ({
      serviceName: intl.formatMessage({ id: allServices.find(({ id }) => serviceId === id)?.name }),
      serviceData: `${servicesMapping[serviceId].bookedAppointments}/${servicesMapping[serviceId].total}`,
    }));
  }, [visibleMonth, eventStatsState, intl, allServices]);

  return (
    <div className={styles.calendar_overview_wrapper} ref={containerElement}>
      <OverviewHeader onBackClick={onClose} visibleMonth={visibleMonth} monthStats={monthStats}/>
      {isDateMonthLoaded && (
        <div className={styles.calendar_container}>
          <EventsOverview
            onScroll={handleScroll}
            data={renderData}
            currentDate={currentDate}
            isLoading={isLoading}
            scrollDate={scrollDate}
            setVisibleMonth={setVisibleMonth}
          />
        </div>
      )}
    </div>
  );
}
