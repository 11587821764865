import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { getResourceIcon } from 'utils/getResourceIcon';

import styles from './RoomsAmount.module.scss';

export type RoomsAmountValues = {
  [key: string]: number;
};

type Props = {
  values: RoomsAmountValues | null;
};

export function RoomsAmount(props: Props) {
  const intl = useIntl();
  const { values } = props;

  const resources = useMemo(() => {
    if (!values) {
      return [];
    }
    return Object.entries(values).map((pair) => ({
      name: pair[0],
      icon: getResourceIcon(`${pair[0]}Resource`, styles.icon),
      value: pair[1],
    }));
  }, [values]);

  if (!values) {
    return null;
  }

  return (
    <Stack className={styles.container}>
      {resources.map((resource) => (
        <Stack
          direction="row"
          className={styles.item}
          alignItems="center"
          justifyContent="space-between"
          key={`resources-${resource.name}`}
        >
          {resource.icon}
          <Typography className={styles.title}>{intl.formatMessage({ id: `resources.${resource.name}` })}</Typography>
          <Typography className={styles.value}>{resource.value}</Typography>
        </Stack>
      ))}
    </Stack>
  );
}
