import { Dayjs } from 'dayjs';

import { Appointment, CalendarEventTypeEnum, Customer, Shift, Service } from 'types';
import { GetAppointmentCustomer, GetAppointmentServices } from '../index';
import { AppointmentFilters, CalendarEvent, CalendarResource } from '../../AppointmentsCalendar/types';
import { mapServiceIdsToServices, sortByServicesCompareFn, TimeHelper } from '../../../utils';
import { ResourceTypeEnum } from '../../../types/ResourceTypeEnum';

type OverflowResources = {
  periods: { from: number; to: number }[];
  num: number;
}[];

export type CalendarData = {
  events: CalendarEvent[];
  resources: CalendarResource[];
  eventTypeCounters: { [key: string]: number };
};

function prepareCustomer(customer?: Customer): CalendarEvent['customer'] {
  return {
    name: customer?.name || '',
    lastName: customer?.lastName || '',
    phoneNumber: customer?.phoneNumber || '',
    email: customer?.email || '',
    gender: customer?.gender || undefined,
  };
}

function canAppointmentBeAddedToOverflow(appointmentType: CalendarEventTypeEnum = CalendarEventTypeEnum.APPOINTMENT) {
  // todo add tasks and other types
  return [
    CalendarEventTypeEnum.APPOINTMENT,
    CalendarEventTypeEnum.WALKIN,
    CalendarEventTypeEnum.BREAK,
    CalendarEventTypeEnum.BLOCKER,
    CalendarEventTypeEnum.TASK,
  ].includes(appointmentType);
}

function findAmountOfEventsForResourceByType(events: CalendarEvent[]): CalendarResource['eventsStatistics'] {
  return events.reduce((acc, curr) => {
    acc[curr.type] = (acc[curr.type] || 0) + 1;
    return acc;
  }, {});
}

function composeEventTitle(genderTitles: { male: string; female: string }, event: Appointment, customer?: Customer): string {
  if (!customer) {
    if (event.title) {
      return event.title;
    }
    return '';
  }
  const name = `${customer.name} ${customer.lastName}`;
  if (customer.gender) {
    const prefix = customer.gender === 'male' ? genderTitles.male : genderTitles.female;
    return `${prefix} ${name}`;
  }

  return name;
}

function getOverFlowResourceId(start: number, end: number, overflowResources: OverflowResources) {
  let overflowResourceNum = 0;
  const from = start;
  const to = end;
  const existingOverflow = overflowResources.find((resource) => {
    if (resource.periods.length < 1) {
      return true;
    }
    const overlappingPeriod = resource.periods.find((period) => {
      if (period.from < from) {
        if (period.to <= from) {
          // periods: [{from: 2, to: 3}], event: {from: 3, to: 4}
          // no overlap
          return false;
        }
        // periods: [{from: 2, to: 6}], event: {from: 3, to: 4}
        // overlap
        return true;
      }
      if (period.from > from) {
        if (period.from < to) {
          // periods: [{from: 3.5, to: 6}], event: {from: 3, to: 4}
          // overlap
          return true;
        }
        // periods: [{from: 4, to: 6}], event: {from: 3, to: 4}
        // no overlap
        return false;
      }
      // periods: [{from: 4, to: 6}], event: {from: 4, to: 4.5}
      // overlap
      return true;
    });
    return !overlappingPeriod;
  });
  if (existingOverflow) {
    overflowResourceNum = existingOverflow.num;
  } else {
    overflowResourceNum = overflowResources.length;
    overflowResources.push({ num: overflowResourceNum, periods: [] });
  }
  overflowResources[overflowResourceNum].periods.push({ from, to });
  return overflowResourceNum;
}

export function isNotInWorkingHours(startMinutes: number, endMinutes: number, workingHours): Boolean {
  if (!workingHours) {
    return false;
  }
  if (workingHours.isLoading) {
    return false;
  }
  if (!workingHours.from || !workingHours.to) {
    return false;
  }
  if (startMinutes < workingHours.from || endMinutes <= workingHours.from) {
    return true;
  }
  if (startMinutes >= workingHours.to || endMinutes > workingHours.to) {
    return true;
  }
  return false;
}

export function buildAppointmentEvents(
  appointments: Appointment[],
  shifts: Shift[],
  employeeNameById: { [employeeId: string]: string },
  allServices: Service[],
  date: Dayjs,
  slotsData: any,
  timeZone: string,
  translations: { male: string; female: string; overflow: string },
  filters?: AppointmentFilters,
  workingHours?: { isLoading?: boolean; from?: number; to?: number; isOpen?: boolean },
  useSlotsFromDB?: Boolean,
): CalendarData {
  const eventTypeCounters = {
    [CalendarEventTypeEnum.APPOINTMENT.toString()]: 0,
    [CalendarEventTypeEnum.TASK.toString()]: 0,
    [CalendarEventTypeEnum.BREAK.toString()]: 0,
    [CalendarEventTypeEnum.BLOCKER.toString()]: 0,
  };

  const filteredServices = filters?.services.map((service) => service.extra.id);
  const filteredType = filters?.focus;
  const overflowResources: OverflowResources = [];
  const overflowEmployeeResources: { [employeeId: string]: OverflowResources } = {};
  const events: any[] = [];
  const resourceEventCounters: { [id: string]: number } = {};
  const dateStartTimestamp = date.startOf('day').valueOf();
  const dateStr = TimeHelper.toStandardFormat(date);

  function addToCounters(assignment: string): void {
    if (!resourceEventCounters[assignment]) {
      resourceEventCounters[assignment] = 0;
    }
    resourceEventCounters[assignment] += 1;
  }

  function addToOverflows(appointment: Appointment, serviceList: Service[], assignment?: string, suggestedEmployee?: string): string {
    const isOverflow = (assignment === 'missed' || !assignment) && canAppointmentBeAddedToOverflow(appointment.type);
    const startTimestamp = dateStartTimestamp + appointment.time * 60000;
    const endTimestamp = startTimestamp + appointment.duration * 60000;
    let resourceId;
    if (isOverflow && shifts.length > 0) {
      if (
        filteredType?.length &&
        !filteredType.filter((eventType) => eventType !== CalendarEventTypeEnum.APPOINTMENT).includes(appointment.type!) &&
        !filteredServices?.length
      ) {
        return '';
      }
      if (filteredServices && filteredServices.length > 0) {
        if (serviceList.length < 1) {
          return '';
        }
        if (!serviceList.every((serviceId) => filteredServices.includes(serviceId.id))) {
          return '';
        }
      }
      const isOverflowWithEmployee = isOverflow && !!suggestedEmployee;
      if (isOverflowWithEmployee) {
        if (!overflowEmployeeResources[suggestedEmployee]) {
          overflowEmployeeResources[suggestedEmployee] = [];
        }
        const overflowResourceId = getOverFlowResourceId(startTimestamp, endTimestamp, overflowEmployeeResources[suggestedEmployee]);
        resourceId = `employee-${ResourceTypeEnum.OVERFLOW}-${suggestedEmployee}-${overflowResourceId}`;
      } else {
        resourceId = `${ResourceTypeEnum.OVERFLOW}-${getOverFlowResourceId(startTimestamp, endTimestamp, overflowResources)}`;
      }
    }
    return resourceId;
  }

  appointments.forEach((appointment) => {
    if (appointment.date !== dateStr) {
      return;
    }
    if (appointment.type) {
      eventTypeCounters[appointment.type] += 1;
    }
    const { duration, title, pinnedEmployee } = appointment;
    const serviceList: Service[] = GetAppointmentServices(appointment, allServices);
    const customer = GetAppointmentCustomer(appointment);
    const startTime = appointment.time;
    const endTime = startTime + duration;
    const startDate = TimeHelper.dateTimeToDayjs(appointment.date, startTime).toDate();
    const endDate = TimeHelper.dateTimeToDayjs(appointment.date, endTime).toDate();

    let assignment = 'missed';
    let suggestedEmployee;
    if (appointment.type && appointment.eventTypeData && (appointment.eventTypeData as { suggestedEmployee?: string }).suggestedEmployee) {
      suggestedEmployee = (appointment.eventTypeData as { suggestedEmployee: string }).suggestedEmployee;
    }
    if (pinnedEmployee && shifts.find((shift) => shift.employeeId === pinnedEmployee)) {
      assignment = pinnedEmployee;
    }

    addToCounters(assignment);

    let isOverflow = false;
    let resourceId = assignment;
    const overflowResourceId = addToOverflows(appointment, serviceList, assignment, suggestedEmployee);
    if (overflowResourceId) {
      isOverflow = true;
      resourceId = overflowResourceId;
    }
    events.push({
      resourceId,
      id: appointment.id,
      title: composeEventTitle(translations, appointment, customer),
      appointmentTitle: title,
      start: startDate,
      startFormatted: TimeHelper.getHHmmTimeFromMinutes(startTime),
      end: endDate,
      endFormatted: TimeHelper.getHHmmTimeFromMinutes(endTime),
      duration,
      services: serviceList,
      customer: prepareCustomer(customer),
      type: appointment.type,
      suggestedEmployee: suggestedEmployee || undefined,
      isOverflow,
      timeFormat: 'HH:mm',
      metadata: appointment.metadata,
      note: appointment.note,
      status: appointment.status,
      date: appointment.date,
      time: appointment.time,
    });
  });

  const slotEvents: any[] = [];
  const dateString = TimeHelper.toStandardFormat(date);

  const slots = slotsData[dateString];
  if (slots) {
    const employeeIds = Object.keys(slots);
    const employeeLen = employeeIds.length;
    for (let employeeIndex = 0; employeeIndex < employeeLen; employeeIndex += 1) {
      const currentEmployee = employeeIds[employeeIndex];
      const currentSlots = slots[currentEmployee];
      if (!resourceEventCounters[currentEmployee]) {
        resourceEventCounters[currentEmployee] = 0;
      }
      if (currentSlots.length > 0) {
        currentSlots.forEach((timeSlot) => {
          const startTimestamp = dateStartTimestamp + timeSlot.time * 60000;
          const endTimestamp = startTimestamp + timeSlot.duration * 60000;
          const startMinutes = timeSlot.time;
          const endMinutes = startMinutes + timeSlot.duration;

          if (isNotInWorkingHours(startMinutes, endMinutes, workingHours)) {
            return;
          }

          const startDate = TimeHelper.dateTimeToDayjs(dateString, timeSlot.time).toDate();
          const endDate = TimeHelper.dateTimeToDayjs(dateString, timeSlot.time + timeSlot.duration).toDate();

          const overlappingEvent = events.find(
            (event) =>
              event.resourceId === currentEmployee &&
              ((event.start <= startDate && event.end > startDate) || (event.start < endDate && event.end >= endDate)),
          );

          if (shifts.length > 0 && (overlappingEvent || currentEmployee === 'missed')) {
            if (filteredType?.length) {
              return;
            }
            if (filteredServices && filteredServices.length > 0) {
              if (timeSlot.services.length < 1) {
                return;
              }
              if (!timeSlot.services.every((serviceId) => filteredServices.includes(serviceId.id))) {
                return;
              }
            }
            const overFlowIn = getOverFlowResourceId(startTimestamp, endTimestamp, overflowResources);
            resourceEventCounters[currentEmployee] += 1;
            slotEvents.push({
              resourceId: `${ResourceTypeEnum.OVERFLOW}-${overFlowIn}`,
              id: `${CalendarEventTypeEnum.SLOT}-${currentEmployee}-${timeSlot.time}`,
              title: '',
              appointmentTitle: '[appointmentTitle]',
              start: startDate,
              startFormatted: TimeHelper.getHHmmTimeFromMinutes(startMinutes),
              duration: timeSlot.duration,
              end: endDate,
              endFormatted: TimeHelper.getHHmmTimeFromMinutes(endMinutes),
              services: mapServiceIdsToServices(allServices, timeSlot.services),
              type: CalendarEventTypeEnum.SLOT,
              timeFormat: 'HH:mm',
              date: dateString,
              time: timeSlot.time,
            });
          } else {
            slotEvents.push({
              resourceId: currentEmployee,
              id: `${CalendarEventTypeEnum.SLOT}-${currentEmployee}-${timeSlot.time}`,
              title: '',
              appointmentTitle: '[appointmentTitle]',
              start: startDate,
              startFormatted: TimeHelper.getHHmmTimeFromMinutes(startMinutes),
              duration: timeSlot.duration,
              end: endDate,
              endFormatted: TimeHelper.getHHmmTimeFromMinutes(endMinutes),
              services: mapServiceIdsToServices(allServices, timeSlot.services),
              type: CalendarEventTypeEnum.SLOT,
              timeFormat: 'HH:mm',
              date: dateString,
              time: timeSlot.time,
            });
          }
        });
      }
    }
  }

  events.push(...slotEvents);

  const shiftsPerEmployee = shifts.reduce((acc: Record<string, Shift[]>, shift) => {
    if (!acc[shift.employeeId]) {
      acc[shift.employeeId] = [];
    }
    acc[shift.employeeId].push(shift);
    return acc;
  }, {});

  const resources: CalendarResource[] = Object.entries(shiftsPerEmployee)
    .reduce((acc: (CalendarResource & { shiftLength: number })[], [employeeId, employeeShifts]) => {
      const shift = employeeShifts.find((item) => date.isSame(item.start, 'day'));
      if (!shift) {
        return acc;
      }
      const services = mapServiceIdsToServices(allServices, shift.services);
      const shiftLength = shift.end - shift.start;

      const calendarShifts = employeeShifts.map((item) => {
        const startDateTime = TimeHelper.timestampToDateAndTimeTz(item.start, timeZone);
        const startDayjs = TimeHelper.dateTimeToDayjs(startDateTime.date, startDateTime.time);

        const endDateTime = TimeHelper.timestampToDateAndTimeTz(item.end, timeZone);
        const endDayjs = TimeHelper.dateTimeToDayjs(endDateTime.date, endDateTime.time);

        return {
          from: startDayjs.format('HH:mm'),
          to: endDayjs.format('HH:mm'),
          weekDay: startDayjs.day(),
        };
      });

      acc.push({
        id: employeeId,
        title: employeeNameById[shift.employeeId],
        shifts: calendarShifts,
        shiftLength,
        services,
      });
      return acc;
    }, [])
    .sort((cur, next) => {
      let curServices: number[] = [];
      if (cur.services && cur.services.length) {
        curServices = cur.services.map((service) => service.displayOrder || 0);
      }
      let nextServices: number[] = [];
      if (next.services && next.services.length) {
        nextServices = next.services.map((service) => service.displayOrder || 0);
      }
      if (curServices.length && nextServices.length) {
        const sorted = sortByServicesCompareFn(curServices, nextServices);
        if (sorted !== 0) {
          return sorted;
        }
        // at this point either they are equal or one array longer than another, skip service ordering
      }

      const shiftSort = next.shiftLength - cur.shiftLength;
      if (shiftSort !== 0) {
        return shiftSort;
      }
      return 0;
    });

  if (!useSlotsFromDB && overflowResources.length > 0) {
    resources.push(
      ...overflowResources.map((resource) => {
        const resourceId = `${ResourceTypeEnum.OVERFLOW}-${resource.num}`;
        return {
          id: resourceId,
          title: `${translations.overflow} ${resource.num + 1}`,
          isOverflow: true,
          services: [],
          shifts: [],
          eventsStatistics: findAmountOfEventsForResourceByType(events.filter((event) => event.resourceId === resourceId)),
        };
      }),
    );
  }
  if (Object.keys(overflowEmployeeResources).length > 0) {
    Object.entries(overflowEmployeeResources).map(([employeeId, employeeResources]) => {
      employeeResources.forEach((resource) => {
        const resourceId = `employee-${ResourceTypeEnum.OVERFLOW}-${employeeId}-${resource.num}`;
        let title = '';
        if (employeeId && employeeNameById[employeeId]) {
          title = employeeNameById[employeeId];
        }
        resources.push({
          id: resourceId,
          title,
          isOverflow: true,
          isEmployeeOverflow: true,
          services: [],
          shifts: [],
          eventsStatistics: findAmountOfEventsForResourceByType(events.filter((event) => event.resourceId === resourceId)),
        });
      });
    });
  }

  return {
    events,
    resources,
    eventTypeCounters,
  };
}

export function getFullNameWithPrefix(data: { name?: string; lastName?: string }, prefix?: string): string {
  if (!(data.name || data.lastName)) {
    return '';
  }

  return [prefix, data.name, data.lastName].filter(Boolean).join(' ');
}
