import React from 'react';
import { Dayjs } from 'dayjs';
import { IconButton, Stack } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import styles from './OverviewHeader.module.scss';
import { useIntl } from 'react-intl';

type Props = {
  date: Dayjs;
  onBackClick: () => void;
};

export function OverviewHeader(props: Props) {
  const { date, onBackClick } = props;
  const intl = useIntl()

  return (
    <div className={styles.header}>
      <div className={styles.navigation}>
        <Stack direction="row" alignItems="center">
          <IconButton color="primary" disableRipple onClick={onBackClick}>
            <ArrowBackIosIcon />
          </IconButton>
          <span className={styles.title}>{intl.formatMessage({id: 'common.reports'})}</span>
        </Stack>
      </div>
    </div>
  );
}
