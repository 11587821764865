import axios from 'axios';
import { getApiUrl } from './apiHelper';

const URL = getApiUrl();

export default async function Client({ url, body, method }: any): Promise<any> {
  try {
    const response = await axios({
      url: `${URL}${url}`,
      method,
      data: body,
      responseType: 'json',
      withCredentials: true,
    });

    if (response.status < 200 || response.status >= 300) {
      throw response.data;
    }
    return response;
  } catch (error: any) {
    if (error && error.response && error.response!.status === 401) {
      window.location = '/' as any;
      return undefined;
    }
    throw error;
  }
}

const apiClient = axios.create({
  baseURL: URL,
  withCredentials: true,
  timeout: 120000,
});

apiClient.interceptors.response.use((response) => response, (error) => {
  const errorMessage = error.response && error.response.data?.error ? error.response.data.error : 'Connection error';
  throw new Error(errorMessage);
});
export { apiClient };
