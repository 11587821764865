import { createSlice } from '@reduxjs/toolkit';
import { getReportsData } from './thunks';
import { DaySummary } from '../../types';

interface InitialState {
  loading: boolean;
  error: any;
  data: any;
  queryHaveResponse: boolean;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  data: { },
  queryHaveResponse: false,
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    updateReportData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    clearReportData: (state) => {
      state.queryHaveResponse = false;
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReportsData.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.queryHaveResponse = false;
    });
    builder.addCase(getReportsData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = { ...state.data, ...action.payload };
      state.queryHaveResponse = !!state.data.monthly;
    });
    builder.addCase(getReportsData.rejected, (state, action) => {
      state.loading = false;
      state.queryHaveResponse = false;
      state.error = action.error;
    });
  },
});

export const { updateReportData, clearReportData } = slice.actions;
export default slice.reducer;
