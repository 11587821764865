import { createAsyncThunk } from '@reduxjs/toolkit';

import { EVENT_STATS_FIND_ERROR } from '../types/calendarOverview';
import CLIENT from '../../utils/CLIENT';

export const getReportsData = createAsyncThunk('reports/get', async (payload: {
  storeId: string;
  enterpriseId: string;
}) => {

  const response = await CLIENT({
    url: `/stores/plot-data?storeId=${payload.storeId}&enterpriseId=${payload.enterpriseId}`,
    method: 'GET',
  });  

  if (!response || !response.data) {
    throw new Error(EVENT_STATS_FIND_ERROR);
  }

  return response.data;
});
