import React from 'react';
import { IconButton, Stack } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import styles from './OverviewHeader.module.scss';

type Props = {
  visibleMonth: string,
  monthStats: { serviceName: string, serviceData: string }[],
  onBackClick: () => void;
};

export function OverviewHeader(props: Props) {
  const { visibleMonth, monthStats, onBackClick } = props;

  return (
    <div className={styles.header}>
      <div className={styles.navigation}>
        <Stack direction="row" alignItems="center">
          <IconButton color="primary" disableRipple onClick={onBackClick}>
            <ArrowBackIosIcon />
          </IconButton>
          <span className={styles.title}>{visibleMonth}</span>
          {monthStats.map(({ serviceName, serviceData }) => (
            <span
              key={serviceName}
              style={{ marginRight: '15px' }}
            >
              {serviceName} <b style={{ marginLeft: '5px' }}>{serviceData}</b>
            </span>
          ))}
        </Stack>
      </div>
    </div>
  );
}
