import { configureStore } from '@reduxjs/toolkit';
import appointments from './appointments/slice';
import calendarSlots from './calendarSlots/slice';
import employees from './employees/slice';
import slots from './slots/slice';
import storeSlice from './store/slice';
import user from './user/slice';
import global from './global/slice';
import peakTimes from './peakTimes/slice';
import eventStats from './eventStats/slice';
import epep from './epep/slice';
import health from './health/slice';
import reports from './reports/slice';
import { SocketIoMiddleware } from './middleware/socketIoMiddleware';

export const store = configureStore({
  reducer: {
    appointments,
    calendarSlots,
    employees,
    slots,
    store: storeSlice,
    peakTimes,
    user,
    global,
    eventStats,
    epep,
    health,
    reports,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(SocketIoMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
