import React, { useState } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { CalendarCompact as AppointmentIcon, IOSSwitch } from '@linetweet/linetweet-ui';
import { BlockerIcon, PauseIcon, WalkinsIcon } from '../../../assets/DesignAssets/BTL/Icons';

import styles from './CalendarFooter.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setUseSlotsFromDB } from '../../../store/global/slice';
import { apiClient } from '../../../utils/CLIENT';
import { updateStoreSettingsUseDemoBreaks } from '../../../store/store/slice';

export function CalendarFooter() {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [demoBreaksUpdatePending, setDemoBreaksUpdatePending] = useState(false);
  const { useSlotsFromDB } = useAppSelector((state) => state.global);
  const storeState = useAppSelector((state) => state.store);

  const onChangeUseDemoBreaks = async (checked) => {
    try {
      setDemoBreaksUpdatePending(true);
      await apiClient.patch('/lt-booking/store', { settings: { useDemoBreaks: checked } });
      dispatch(updateStoreSettingsUseDemoBreaks(checked));
    } catch (e) {
      console.error(e);
    } finally {
      setDemoBreaksUpdatePending(false);
    }
  };

  return (
    <Stack className={styles.container}>
      <Stack className={clsx(styles.item, styles.appointment)}>
        <AppointmentIcon className={styles.icon} />
        <Typography className={styles.text}>{intl.formatMessage({ id: 'appointment.appointment' })}</Typography>
      </Stack>
      <Stack className={clsx(styles.item, styles.walkin)}>
        <WalkinsIcon className={styles.icon} width={20} height={20} />
        <Typography className={styles.text}>{intl.formatMessage({ id: 'appointment.walkin' })}</Typography>
      </Stack>
      <Stack className={clsx(styles.item, styles.break)}>
        <PauseIcon className={styles.icon} />
        <Typography className={styles.text}>{intl.formatMessage({ id: 'appointment.break' })}</Typography>
      </Stack>
      <Stack className={clsx(styles.item, styles.blocker)}>
        <BlockerIcon className={styles.icon} />
        <Typography className={styles.text}>{intl.formatMessage({ id: 'appointment.blocker' })}</Typography>
      </Stack>
      <Stack direction="row" marginLeft="auto" alignItems="center">
        {demoBreaksUpdatePending ? (
          <Box marginRight={1}>
            <CircularProgress size="16px" disableShrink />
          </Box>
        ) : null}
        {/* <Box marginRight={2}>
          <Typography fontWeight={500} fontSize={16}>
            Sonova Breaks
          </Typography>
        </Box>
        <Box marginRight={4}>
          <IOSSwitch
            disabled={demoBreaksUpdatePending}
            checked={storeState.data.settings.useDemoBreaks}
            onChange={(_e, checked) => onChangeUseDemoBreaks(checked)}
          />
        </Box> */}
        <Box marginRight={2}>
          <Typography fontWeight={500} fontSize={16}>
            Sonova Data
          </Typography>
        </Box>
        <Box>
          <IOSSwitch checked={useSlotsFromDB} onChange={(_e, checked) => dispatch(setUseSlotsFromDB(checked))} />
        </Box>
      </Stack>
    </Stack>
  );
}
