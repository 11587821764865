import React, { useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';

import styles from './EventsOverview.module.scss';
import { DayOverviewType } from '../../../types';
import { TimeHelper } from '../../../utils';
import { CalendarDay } from '../subcomponents';

type Props = {
  currentDate: Dayjs;
  scrollDate: Dayjs;
  data: Record<string, DayOverviewType>;
  isLoading: { prev: boolean; next: boolean };
  onScroll: (isNext: boolean) => void;
  setVisibleMonth: (month: string) => void;
};

export function EventsOverview(props: Props) {
  const { currentDate, data, scrollDate, isLoading, onScroll, setVisibleMonth } = props;

  const observerTargetNext = useRef(null);
  const observerTargetPrev = useRef(null);
  const currentElement = useRef(null);
  const gridRef = useRef<HTMLDivElement | null>(null);

  const [isInitialSetup, setIsInitialSetup] = useState(true);

  const createObserver = (observerTarget, handleScroll: (isNext: boolean) => void, isNext: boolean) => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0] && entries[0].isIntersecting && !isInitialSetup) {
          handleScroll(isNext);
        }
      },
      { threshold: 0.5 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  };

  useEffect(() => {
    const cleanupPrev = createObserver(observerTargetPrev, onScroll, false);
    const cleanupNext = createObserver(observerTargetNext, onScroll, true);
    setIsInitialSetup(false);

    return () => {
      cleanupPrev();
      cleanupNext();
    };
  }, [onScroll, observerTargetPrev, observerTargetNext]);

  const scrollTarget = useMemo(() => {
    const scrollStr = TimeHelper.toStandardFormat(scrollDate);
    return scrollStr;
  }, [scrollDate, currentDate]);

  useEffect(() => {
    if (currentElement.current && scrollTarget) {
      (currentElement.current as HTMLElement).scrollIntoView(true);
    }
  }, [currentElement, scrollTarget, data]);

  const days = useMemo(
    () =>
      Object.values(data)
        .sort((a, b) => a.date - b.date)
        .map((dayData) => TimeHelper.toDayjs(dayData.date)),
    [data],
  );

  const weekDays = useMemo(() => {
    const result = dayjs.localeData().weekdaysShort();

    const elements = result.splice(0, dayjs.localeData().firstDayOfWeek());
    result.push(...elements);

    return result;
  }, []);

  const checkVisibleMonth = () => {
    if (gridRef.current) {
      const visibleDays: string[] = [];
      const gridElements = gridRef.current.querySelectorAll('.day-for-check');

      gridElements.forEach((el) => {
        const dayElement = el as HTMLElement;
        const rect = dayElement.getBoundingClientRect();

        // Check if the element is in the viewport
        if (rect.top >= 0 && rect.top <= window.innerHeight) {
          const date = dayElement.getAttribute('data-date');
          if (date) {
            visibleDays.push(date);
          }
        }
      });

      setVisibleMonth(
        visibleDays.reduce((a, b) => (visibleDays.filter(x => x === a).length > visibleDays.filter(x => x === b).length ? a : b))
      );
    }
  };

  useEffect(() => {
    checkVisibleMonth();
  }, []);

  return (
    <div onScroll={checkVisibleMonth} className={styles.calendar}>
      <div className={styles.calendar_wrapper}>
        <div ref={gridRef} className={styles.calendar_content}>
          {weekDays.map((day) => (
            <div key={day} className={styles.day_title}>
              {day}
            </div>
          ))}
          <div ref={observerTargetPrev} className={styles.loading}>
            {isLoading.prev && <CircularProgress disableShrink />}
          </div>
          {days.map((day) => {
            const dayStr = TimeHelper.toStandardFormat(day);
            return (
              <div
                data-date={day.format('MMMM YYYY')}
                key={dayStr}
                className={clsx('day-for-check', styles.day_content)}
                ref={dayStr === scrollTarget ? currentElement : null}
              >
                <CalendarDay date={day} data={data[dayStr]} currentDate={currentDate} />
              </div>
            );
          })}
          <div ref={observerTargetNext} className={styles.loading}>
            {isLoading.next && <CircularProgress disableShrink />}
          </div>
        </div>
      </div>
    </div>
  );
}
